export const APP_NAME = 'UnTrash'
export const PERSIST_STORE_NAME = 'root'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const MANUAL_UPLOAD = 'manualUpload'
export const BULK_UPLOAD = 'bulkUpload'
export const CONFIRMATION = 'confirmation'
export const TRANSACTION_DETAILS = 'transactionDetails'
export const VIEW_ANALYTICS = 'viewAnalytics'
export const CONFIRMATION_WITH_CHECKBOX = 'confirmationWithCheckbox'
export const SCOPE = 'scope'
// Todo: Write meaningful name
export const SCREEN1 = 'screen1'
export const CONTRIBUTORS = 'contributors' 
export const CONTRIBUTERS = 'contributers'
export const MONTH_NAME = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]
export const MONTH_FULLNAME = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
};
export const MIN_THRESHOLD = 35
export const MAX_THRESHOLD = 15
export const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]$/
export const CIN_REGEX = /^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/
export const GST_REGEX =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/
export const GST_DESCRIPTION = [
    'It should be 15 characters long.',
    'The first 2 characters should be a number.',
    'The next 10 characters should be the PAN number of the taxpayer.',
    'The 13th character (entity code) should be a number from 1-9 or an alphabet.',
    'The 14th character should be Z.',
    'The 15th character should be an alphabet or a number.',
]
export const CIN_DESCRIPTION = [
    'CIN is a 21 digits alpha-numeric code.',
    'It starts with either alphabet letter U or L.',
    'Next five characters are reserved for digits (0-9).',
    'Next two places are occupied by alphabet letters(A-Z-a-z).',
    'Next four places are taken by digits(0-9).',
    'Next three characters are reserved for alphabet letters (A-Za-z).',
    'Next six characters are digits(0-9)',
]
export const PAN_DESCRIPTION = [
    'It should be 10 characters long.',
    'The first five characters should be any upper case alphabets.',
    'The next four-characters should be any number from 0-9.',
    'The last(tenth) character should be any upper case alphabet.',
    'It should not contain any white spaces.',
]
export const EDIT_DATA = 'editData'
export const qualityOptions: Array<{label: string, value: string}> = [
    { label: 'Virgin', value:'Virgin' },
    { label: 'Refurbished', value:'Refurbished'},
    { label: 'Recycle', value:'Recycle'}
]

export const stateOptions: Array<{label: string, value: string}> = [
    { label: 'Solid', value:'Solid' },
    { label: 'Liquid', value:'Liquid'},
    { label: 'Gas', value:'Gas'}
]

export const lcaScreenNames = {
    MANUFACTURING_PLANT: 'Manufacturing Plant',
    FINAL_PRODUCT: 'Final Product',
    RAW_MATERIAL : 'Raw Material',
    PROCESS: 'Process',
    WASTE: 'Waste',
    PRODUCTION_QUANTITY: 'Production Quantity',
    SUMMARY: 'Summary'
}

export const lcaOutputScreenNames = {
    OVERALL_IMPACT: 'Overall Impact',
    PRODUCT_IMPACT: 'Product Level Impact',
    RAW_MATERIAL_IMPACT : 'Raw Material Emissions',
    PROCESS_IMPACT: 'Process Level Impact',
    TRANSPORTATION_IMPACT: 'Transportation Impact',
}